import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Div'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import Image from '../components/ui/Image'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'

import Layout from '../components/layout'
import SideMenu from '../components/SideMenu'
import DesktopSideBar from '../components/DesktopSideBar'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'

const FullHeightContainer = styled(Flex)`
  min-height: 100vh;
`

const AboutPage = ({}) => {
  return (
    <Layout>
      <Helmet
        title={'About SMS ANDA | SMS ANDA'}
        lang="en"
        description={`Find out about the internet's most extensive collection of disposable phone numbers | Receive SMS online for free with no ads or registration.`}
      >
        <html lang={'en'} />
      </Helmet>
      <FullHeightContainer flexDirection="column" alignItems="center">
        <Header />
        <Flex maxWidth="900px" pt="60px" flexDirection="column">
          <H1 mb="20px">About</H1>
          <Text mb="40px">
            SMS ANDA is the largest database of temporary online SMS numbers on the internet. We've strived to provide users with temporary phone
            numbers across numerous countries, serving as an essential tool for privacy and protection in the digital age. We hope we have an
            intuitive interface to make your user experience seemless!
          </Text>
          <Text mb="40px">Our service is 100% free and will always be so. Built in the United Kingdom, London.</Text>
          <H1 mb="20px">Contact us</H1>
          <Text>Please email us at smsandacontact@gmail.com if you require any assistance or would like to give feedback, we're always open!</Text>
        </Flex>
      </FullHeightContainer>
      <Footer iso2={'en'} />
    </Layout>
  )
}
export default AboutPage

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
