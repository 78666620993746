import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer } from './ui/Div'
import Button from './ui/Button'
import Spinner from './ui/Spinner'
import { colors, smallBoxShadow } from './ui/helpers.js'
import { urlToString } from './ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from './ui/Text'
import OnlineIcon from './ui/OnlineIcon'
import EzoicAd from './EzoicAd'

const TelephoneContainer = styled(Flex)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
`

const DesktopSideBarContainer = styled(DesktopContainer)`
  flex-direction: column;
  width: 350px;
  min-width: 350px;
  background-color: white;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 32px;
  padding-right: 24px;
`

const FlagImage = styled.img`
  border-radius: 8px;
  margin-right: 16px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const DesktopSideBar = ({ iso2, telephone, countryTelephones, country, telephones }) => {
  if (countryTelephones.length === 0) {
    return null
  }
  return (
    <DesktopSideBarContainer>
      <EzoicAd id="109" />
      <Text fontWeight="bold" lineHeight="34px" fontSize="30px" mb={4}>
        {`More ${urlToString(country)} Numbers:`}
      </Text>
      {countryTelephones.map((telephone, i) => {
        return (
          <>
            <a key={telephone} href={`/${country}/${telephone}/`}>
              <TelephoneContainer>
                <Flex alignItems="center">
                  <Flag iso2={countryToIso2[country]} />
                  <Text width="100%" fontSize="26px" textAlign="right" mr={2}>
                    +{telephone}
                  </Text>
                  <OnlineIcon />
                </Flex>
              </TelephoneContainer>
            </a>
          </>
        )
      })}
    </DesktopSideBarContainer>
  )
}
export default DesktopSideBar

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
